<template>
  <div class="wave-box"></div>
</template>

<script>
export default {
  name: 'Wave'
}
</script>

<style lang="scss" scoped>
.wave-box {
  position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #76daff;
    overflow: hidden;
    z-index: -1;

  &:before, &:after {
      content: "";
      position: absolute;
      left: 50%;
      min-width: 400vw;
      min-height: 400vw;
      background-color: #fff;
      animation-name: rotate;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
  }

  &:before {
      bottom: 15vh;
      border-radius: 45%;
      animation-duration: 20s;
  }

  &:after {
      bottom: 12vh;
      opacity: .5;
      border-radius: 47%;
      animation-duration: 20s;
  }
}
 
@keyframes rotate {
  0% {
      transform: translate(-50%, 0) rotateZ(0deg);
  }
  50% {
      transform: translate(-50%, -2%) rotateZ(180deg);
  }
  100% {
      transform: translate(-50%, 0%) rotateZ(360deg);
  }
}
</style>